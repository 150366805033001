import { graphql, useStaticQuery } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import React from 'react';

import Article, {
  ArticleHeader,
  ArticleTitle,
  ArticleContent,
  ArticleParagraph,
} from 'components/Article';
import albumsLogo from 'images/guidelines/albums-logo.png';

import styles from './Guidelines.module.scss';

const Guidelines: React.FC = () => {
  const {
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
  } = useStaticQuery(
    graphql`
      query {
        image1: file(relativePath: { eq: "guidelines/image-1.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image2: file(relativePath: { eq: "guidelines/image-2.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image3: file(relativePath: { eq: "guidelines/image-3.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image4: file(relativePath: { eq: "guidelines/image-4.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image5: file(relativePath: { eq: "guidelines/image-5.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image6: file(relativePath: { eq: "guidelines/image-6.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image7: file(relativePath: { eq: "guidelines/image-7.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image8: file(relativePath: { eq: "guidelines/image-8.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image9: file(relativePath: { eq: "guidelines/image-9.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image10: file(relativePath: { eq: "guidelines/image-10.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image11: file(relativePath: { eq: "guidelines/image-11.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
        image12: file(relativePath: { eq: "guidelines/image-12.png" }) {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp_noBase64,
            }
          }
        }
      }
    `,
  );

  return (
    <Article>
      <ArticleHeader>
        <ArticleTitle level={1}>
          Albums Guidelines
        </ArticleTitle>
        <div className={styles.headerSeparator} />
        <div className={styles.meta}>
          <span className={styles.published}>January, 2020</span>
          <a href="/Albums.zip" download className={styles.downloadMedia}>
            Download media archive.zip
          </a>
        </div>
      </ArticleHeader>
      <ArticleContent>
        <ArticleTitle level={2}>
          When to Use the Glyph
        </ArticleTitle>
        <ArticleParagraph>
          Use the glyph to point to your presence on Albums. The App Icon (shown below) is only used
          if you are showing it on a device with other apps or if you are encouraging people to
          download the Albums app. If you’re representing Albums in any other way, you should use
          the glyph.
        </ArticleParagraph>
        <div className={styles.imageContainer}>
          <div>
            <img src={albumsLogo} className={styles.albumsLogo} alt="Albums logo" />
          </div>
        </div>
        <ArticleParagraph>
          Only use the glyph files available here. Our downloadable assets include a black or white
          glyph on a black or white background and a multicolor version. You can change the glyph to
          any solid color, as long as all other aspects of its design stay the same. We recommend
          showing the glyph in black or white. The glyph should always be paired with a call to
          action button (example: Follow us on Albums!), unless you’re showing it in a lineup of
          social media icons.
        </ArticleParagraph>

        <ArticleTitle level={2}>
          Minimum Clear Space
        </ArticleTitle>

        <ArticleParagraph>
          Clear space ensures legibility and visual impact by isolating the glyph or the app icon
          from competing visual elements. Determine the minimum clear space around the glyph or the
          app icon by measuring one half of the glyph’s and app icon size on all sides.
        </ArticleParagraph>

        <div className={styles.imagesContainer}>
          <GatsbyImage fluid={image1.childImageSharp.fluid} />
          <GatsbyImage fluid={image2.childImageSharp.fluid} />
        </div>

        <ArticleTitle level={2}>
          Minimum Size
        </ArticleTitle>

        <ArticleParagraph>
          A minimum size ensures that the impact and legibility of the glyph is not compromised in
          application. Always maintain their set proportions and never show the glyph smaller than
          29x29 pixels.
        </ArticleParagraph>

        <div className={styles.imageContainer}>
          <GatsbyImage fluid={image3.childImageSharp.fluid} className={styles.image} />
        </div>

        <ArticleTitle level={2}>
          Examples
        </ArticleTitle>

        <div className={styles.imagesContainer}>
          <figure>
            <GatsbyImage fluid={image4.childImageSharp.fluid} />
            <figcaption>
              The copy is just outside the minimum clear space.
            </figcaption>
          </figure>
          <figure>
            <GatsbyImage fluid={image5.childImageSharp.fluid} />
            <figcaption>
              The logo is well outside the minimum clear space.
            </figcaption>
          </figure>
          <figure>
            <GatsbyImage fluid={image6.childImageSharp.fluid} />
            <figcaption>
              This copy line is too close to the glyph.
            </figcaption>
          </figure>
          <figure>
            <GatsbyImage fluid={image7.childImageSharp.fluid} />
            <figcaption>
              The logo is too close to the glyph.
            </figcaption>
          </figure>
          <figure>
            <GatsbyImage fluid={image8.childImageSharp.fluid} />
            <figcaption>
              This glyph has been reshaped.
            </figcaption>
          </figure>
          <figure>
            <GatsbyImage fluid={image9.childImageSharp.fluid} />
            <figcaption>
              This glyph has been rotated.
            </figcaption>
          </figure>
        </div>

        <ArticleParagraph>
          Here are a few different ways to style the Albums glyph in a line up with other social
          media icons.
        </ArticleParagraph>

        <div className={styles.imagesContainer}>
          <figure>
            <GatsbyImage fluid={image10.childImageSharp.fluid} />
            <figcaption>
              Using a white glyph on a gray background.
            </figcaption>
          </figure>
          <figure>
            <GatsbyImage fluid={image11.childImageSharp.fluid} />
            <figcaption>
              Using the white glyph with a solid background,
              as other icons use their brand colors.
            </figcaption>
          </figure>
          <figure>
            <GatsbyImage fluid={image12.childImageSharp.fluid} />
            <figcaption>
              Using a shade of gray for consistency.
            </figcaption>
          </figure>
        </div>
      </ArticleContent>
    </Article>
  );
};

export default Guidelines;
