import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from 'components/layout';

import GuidelinesEN from '../articles/en/Guidelines';

const GuidelinesPage: React.FC = () => (
  <Layout>
    <Helmet>
      <title>Guidelines</title>
      <meta name="og:title" content="Guidelines" />
      <meta name="twitter:title" content="Guidelines" />
    </Helmet>
    <GuidelinesEN />
  </Layout>
);

export default GuidelinesPage;
